import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from "./sections/Header.jsx";
import Hero from "./sections/Hero.jsx";
import Features from "./sections/Features.jsx";
import Faq from "./sections/Faq.jsx";
import Download from "./sections/Download.jsx";
import Footer from "./sections/Footer.jsx";
import Projects from "./sections/Projects.jsx"
import Team from "./sections/KnowUs.jsx"
import Form from "./components/Form.jsx";
import ProtectedRoute from "./components/ProtectedRoute"
import NotFound from "./pages/NotFound.jsx";
import Login from "./pages/Login.jsx";
import Register from "./pages/Register.jsx";
import EmailVerification from './components/EmailVerification';
import { useState, useEffect } from 'react';
import LoadingScreen from './components/LoadingScreen';

function Logout() {
  localStorage.clear()
  return <Navigate to="/signup" />
}

function RegisterAndlogout() {
  localStorage.clear()
  return <Register />
}

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simula la carga de recursos
    const loadResources = async () => {
      // Aquí puedes cargar tus recursos, por ejemplo, imágenes, datos, etc.
      await new Promise(resolve => setTimeout(resolve, 2000)); // Simula un retraso de 2 segundos
      setIsLoading(false);
    };

    loadResources();
  }, []);

  return (
    <Router>
      <main className="overflow-hidden">
        {isLoading && <LoadingScreen />}
        {!isLoading && (
          <>
            <Header />
            <Routes>
              <Route path="/" element={
                <>
                  <Hero />
                  <Projects />
                  <Features />
                  <Faq />
                  <Download />
                  {/*<Team />*/}
                </>
              } />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/register" element={<RegisterAndlogout />} />
              <Route path="/verify-email" element={<EmailVerification />} />
              <Route path="*" element={<NotFound />}></Route>
              {/* Rutas protegidas */}
              <Route path="/protected" element={
                <ProtectedRoute>
                  {/* Componentes que requieren autenticación */}
                </ProtectedRoute>
              } />
            </Routes>
            <Footer />
          </>
        )}
      </main>
    </Router>
  );
};

export default App;
